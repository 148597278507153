import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Nav = () => {
  const { t } = useTranslation("translation", { keyPrefix: "menu" });
  const { i18n } = useTranslation("translation");

  const [flag, setflag] = useState(i18n.language)
  
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setflag(lng)
  }

  return (
    <nav className="mainmenu-nav" >
      <ul className="mainmenu">
        {/* ! lOGO ! */}
        <li className="header-logo" style={{width: 50}}></li>
        {/* ! HOME PAGE ! */}
        <li>
          <Link to={process.env.PUBLIC_URL + "/"}>
            {t("home")}
          </Link>
        </li>
        {/* ! CORPORATE ! */}
        <li className="menu-item-has-children">
          <Link to="#">
            {t("corporate")}
            <FaAngleDown />{" "}
          </Link>
          <ul className="axil-submenu">
            <li>
              <Link to={process.env.PUBLIC_URL + "/about-us"}>
                {t("aboutUs")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/mission-vision"}>
                {t("missionVision")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/hr-policy"}>
                {t("hrPolicy")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/company-policy"}>
                {t("companyPolicy")}
              </Link>
            </li>
          </ul>
        </li>
        {/* ! SOLUTIONS ! */}
        <li className="menu-item-has-children">
          <Link to="#">
            {t("solutions")}
            <FaAngleDown />{" "}
          </Link>
          <ul className="axil-submenu">
            <li>
              <Link to={process.env.PUBLIC_URL + "/solution-software"}>
                {t("softwareSolutions")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/solution-content"}>
                {t("contentSolutions")}
              </Link>
            </li>
          </ul>
        </li>
        {/* ! REFERENCE ! */}
        <li>
          <Link to={process.env.PUBLIC_URL + "/references"}>
            {t("reference")}
          </Link>
        </li>
        {/* ! INFORMATION AND SUPPORT ! */}
        <li className="menu-item-has-children">
          <Link to="#">
            {t("infoAndSupport")} <FaAngleDown />
          </Link>
          <ul className="axil-submenu">
            <li>
              <Link to={process.env.PUBLIC_URL + "/FAQ"}>
                {t("frequentQuestions")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/demo-request"}>
                {t("demoRequest")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/appointment-presentation"}>
                {t("appointmentPresentation")}
              </Link>
            </li>
          </ul>
        </li>
        {/* ! LANGUAGE SWITCHER ! */}
        <li className="menu-item-has-children">
          <Link to="#">
            {flag === 'tr' ? (
              <img 
                alt="tr flag" width={30} 
                src={process.env.PUBLIC_URL + "/images/flag/tr.svg"} 
              />
            ) : (
              <img 
                alt="en flag" width={30} 
                src={process.env.PUBLIC_URL + "/images/flag/en.svg"} 
              />
            )}
          </Link>
          <ul className="axil-submenu">
            <li>
              <Link to="#" onClick={() => changeLanguage("tr")} className="py-1">
                <img 
                  alt="tr flag" width={25} 
                  src={process.env.PUBLIC_URL + "/images/flag/tr.svg"} 
                />
                <span className="ms-1">
                  {t("turkish")}
                </span>
              </Link>
            </li>
            <li>
              <Link to="#" onClick={() => changeLanguage("en")} className="py-1 pointer-cursor">
                <img 
                  alt="en flag" width={25} 
                  src={process.env.PUBLIC_URL + "/images/flag/en.svg"} 
                />
                <span className="ms-1">
                  {t("english")}
                </span>
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  )
}
export default Nav;