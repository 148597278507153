import React from "react";
import SEO from "../../common/SEO";
import ColorSwitcher from "../../elements/switcher/ColorSwitcher";
import Header from "../../common/header/Header";
import FooterOne from "../../common/footer/FooterOne";
import Brands from "../home/Brands";

const References = () => {
  return (
    <>
      <SEO title="company-policy" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <Header />
        <section className="section-padding-2 bg-color-light">
          <Brands />
        </section>
        <FooterOne />
      </main>
    </>
  )
}
export default References