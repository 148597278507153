import React from "react";
import SEO from "../../common/SEO";
import ColorSwitcher from "../../elements/switcher/ColorSwitcher";
import Header from "../../common/header/Header";
import FooterOne from "../../common/footer/FooterOne";
import BcrumbBannerTwo from "../../elements/breadcrumb/BcrumbBannerTwo";
import Tilty from 'react-tilty';
import { useTranslation } from "react-i18next";

const MissionVision = () => {
  const { t } = useTranslation("translation", { keyPrefix: "missionVision" });

  return (
    <>
      <SEO title="mission-vision" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <Header />
        <BcrumbBannerTwo
          title={t("mission")}
          paragraph={t("ourMission")}
          styleClass="thumbnail-1"
          mainThumb={process.env.PUBLIC_URL + "/images/missionVision/cooperation.png"}
        />
        <div className="row"> 
          <div className="col-lg-6">
            <div className={`case-study-featured-thumb thumb-1`}>
              <Tilty perspective={2000}>
                  <img 
                    src={process.env.PUBLIC_URL + "/images/missionVision/cooperation-2.png"} 
                    alt="Illustration"
                  />
              </Tilty>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6 offset-xl-1">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                  <h2 className="title"> {t("vision")}  </h2>
                  <p dangerouslySetInnerHTML={{ __html: t("ourVision")}}></p>
              </div>
            </div>
          </div>
        </div>
        <ul className="list-unstyled shape-group-19">
          <li className="shape shape-1">
              <img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Bubble" />
          </li>
          <li className="shape shape-2">
              <img src={process.env.PUBLIC_URL + "/images/others/line-7.png"} alt="Bubble" />
          </li>
        </ul>
        <FooterOne />
      </main>
    </>
  );
};
export default MissionVision;