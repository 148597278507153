import React from 'react';
import SEO from "../../common/SEO";
import ColorSwitcher from "../../elements/switcher/ColorSwitcher";
import Header from "../../common/header/Header";
import FooterOne from "../../common/footer/FooterOne";
import Tilty from 'react-tilty';
import { useTranslation } from "react-i18next";
import ContactForm from '../../component/contact/ContactForm';

const Appointment = () => {
  // * translation
  const { t } = useTranslation("translation", { keyPrefix: "appointment" });

  return (
    <>
      <SEO title="company-policy" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <Header />
        <div className="section-padding-2">
          <div className="container">
            <div className="row">
              <div className="row align-items-center">
                <ContactForm 
                  title={t("formTitle")}
                  subTitle={t("formSubtitle")}
                  type="appointment"
                />
                <div className="col-lg-6">
                  <div className="banner-thumbnail">
                    <Tilty perspective={2000} reset={false}>
                      <img alt="Illustration" 
                        src={process.env.PUBLIC_URL + "/images/appointment/appointment-img-1.png"} 
                      />
                    </Tilty>
                  </div>
                </div>
                </div>
            </div>
          </div>
        </div>
        <FooterOne />
      </main>
    </>
  )
}
export default Appointment