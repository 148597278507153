import React from "react";
import SEO from "../../common/SEO";
import ColorSwitcher from "../../elements/switcher/ColorSwitcher";
import Header from "../../common/header/Header";
import FooterOne from "../../common/footer/FooterOne";
import BcrumbBannerTwo from "../../elements/breadcrumb/BcrumbBannerTwo";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation("translation", { keyPrefix: "aboutUs" });
  return (
    <>
      <SEO title="About us" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <Header />
        <BcrumbBannerTwo
          title={t("aboutUsLabel")}
          paragraph={t("aboutUsText")}
          styleClass="thumbnail-1"
          mainThumb={process.env.PUBLIC_URL + "/images/about/about-img-1.png"}
        />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};
export default AboutUs;