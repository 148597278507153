import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import ScrollToTop from "./component/scrollToTop/ScrollToTop"
// * css imports
import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/scss/app.scss"

// * Home Pages Import
import Home from './pages/home'
// * Corporate Pages Import
import AboutUs from "./pages/corporate/AboutUs"
import MissionVision from "./pages/corporate/MissionVision"
import HrPolicy from "./pages/corporate/HrPolicy"
import CompanyPolicy from "./pages/corporate/CompanyPolicy"
// * Solution Pages Import
import SoftwareSolutions from "./pages/solutions/SoftwareSolutions"
import ContentSolutions from "./pages/solutions/ContentSolutions"
// * References Pages Import
import References from "./pages/references/References"
// * Information And Support Pages Import
import FAQ from "./pages/informationNsupport/FAQ"
import DemoRequest from "./pages/informationNsupport/DemoRequest"
import Appointment from "./pages/informationNsupport/Appointment"
// * Error Pages Import
import ErrorPage from "./pages/404"

const App = () => {
  return (
    <Router>
		<ScrollToTop>
			<Routes>
        {/* ! HOME PAGE ! */}
				<Route 
          path={process.env.PUBLIC_URL + "/"} 
          element={<Home />}
        />
        {/* ! CORPORATE ! */}
        <Route 
          path={process.env.PUBLIC_URL + "/about-us"} 
          element={<AboutUs />} 
        />
        <Route 
          path={process.env.PUBLIC_URL + "/mission-vision"} 
          element={<MissionVision />} 
        />
        <Route 
          path={process.env.PUBLIC_URL + "/hr-policy"} 
          element={<HrPolicy />}
        />
				<Route 
          path={process.env.PUBLIC_URL + "/company-policy"} 
          element={<CompanyPolicy />}
        />
        {/* ! SOLUTIONS ! */}
        <Route 
          path={process.env.PUBLIC_URL + "/solution-software"} 
          element={<SoftwareSolutions />}
        />
        <Route 
          path={process.env.PUBLIC_URL + "/solution-content"} 
          element={<ContentSolutions />}
        />
        {/* ! REFERENCE ! */}
				<Route 
          path={process.env.PUBLIC_URL + "/references"} 
          element={<References />}
        />
        {/* ! INFORMATION AND SUPPORT ! */}
	      <Route 
          path={process.env.PUBLIC_URL + "/FAQ"} 
          element={<FAQ />}
        />
        <Route 
          path={process.env.PUBLIC_URL + "/demo-request"} 
          element={<DemoRequest />}
        />
        <Route 
          path={process.env.PUBLIC_URL + "/appointment-presentation"} 
          element={<Appointment />}
        />
        {/* ! ERROR ! */}
        <Route
          path='*'
          element={<ErrorPage />}
        />
        </Routes>
      </ScrollToTop>
    </Router>
  )
}
export default App