import React from 'react';
import SEO from "../../common/SEO";
import ColorSwitcher from "../../elements/switcher/ColorSwitcher";
import Header from "../../common/header/Header";
import FooterOne from "../../common/footer/FooterOne";
import { useTranslation } from "react-i18next";
import Accordion from 'react-bootstrap/Accordion';
import ContactForm from '../../component/contact/ContactForm';
import { FaQuestionCircle } from 'react-icons/fa';

const FAQ = () => {
  // * translation
  const { t } = useTranslation("translation", ["faq", "alertMessages"])
  const questions = t("faq.questions", { returnObjects: true })
  const answers = t("faq.answers", { returnObjects: true })

  return (
    <>
      <SEO title="company-policy" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <Header />
        <div className="section-padding-2">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="why-choose-us">
                  <div className="section-heading heading-left">
                    <h3 className="title"> {t("faq.title")} </h3>
                  </div>
                  <Accordion defaultActiveKey="1">
                    {questions.map((question, index) => (
                      <Accordion.Item eventKey={index + 1} key={index}>
                        <Accordion.Header> 
                          <div>
                            <FaQuestionCircle /> 
                          </div>
                          {question}
                        </Accordion.Header>
                        <Accordion.Body>{answers[index]}</Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              </div>
              <ContactForm 
                title={t("faq.formTitle")}
                subTitle={t("faq.formTSubtitle")}
                type="faq"
              />        
            </div>
          </div>
        </div>
        <FooterOne />
      </main>
    </>
  )
}
export default FAQ