import React, {useRef, useState} from 'react';
import { useTranslation } from "react-i18next";
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';

const ContactForm = (props) => {
  const { 
    title, 
    subTitle,
    type
} = props
  // * states 
  const [showresult, setshowresult] = useState(0)
  const [resultMsg, setresultMsg] = useState(null)
  // * ref 
  const form = useRef(null)
  // * translation
  const { t } = useTranslation("translation", ["contactForm", "alertMessages"])

  const sendEmail = (e) => {
    e.preventDefault();
    const submitValues = {
      title: document.getElementById("cName").value,
      message: `(${type}) - ${document.getElementById("cMessage").value}`,
      email: document.getElementById("cEmail").value,
      phone: document.getElementById("cPhone").value,
    }
    axios.post(process.env.REACT_APP_MAIL_API , null, { params: submitValues })    
    .then((res) => {
      if (res && res.data && res.data.isSuccess) {
        setresultMsg(t("alertMessages.mailSuccess"))
        setshowresult(1)
      } else {
        setresultMsg(t("alertMessages.mailErr"))
        setshowresult(2)
      }
    })
    form.current.reset()
  };

  setTimeout(() => {
    if (showresult !== 0) setshowresult(0)
  }, 5000)

  return (
    <>
      <div className="col-xl-5 col-lg-6 offset-xl-1">
        <div className="contact-form-box shadow-box mb--30">
          {title && ( <h4 className="title"> {title} </h4> )}
          {subTitle && ( <h5> {subTitle} </h5> )}
          <form ref={form} onSubmit={sendEmail} className="contact-form">
            <div className="form-group">
              <label> {t("contactForm.name")} </label>
              <input type="text" className="form-control" name="contact-name" id='cName'
                placeholder={t("contactForm.name")} required 
              />
            </div>
            <div className="form-group">
              <label> {t("contactForm.email")} </label>
              <input type="email" className="form-control" name="contact-email" id='cEmail'
                placeholder={t("contactForm.email")} required 
              />
            </div>
            <div className="form-group mb--40">
              <label> {t("contactForm.phone")} </label>
              <input type="tel" className="form-control" name="contact-phone" id='cPhone'
                placeholder={t("contactForm.phone")} required 
              />
            </div>
            <div className="form-group mb--40">
              <label> {t("contactForm.message")} </label>
              <input type="tel" className="form-control" name="contact-message" id='cMessage'
                placeholder={t("contactForm.message")} required 
              />
            </div>
            <div className="form-group">
              <button type="submit" className="axil-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn">
                {t("contactForm.formButton")}
              </button>
            </div>
            <div className="form-group">
              {showresult !== 0 && (
                showresult === 1 ? (
                  <Alert variant="success" className="success-msg">
                    {resultMsg} 
                  </Alert>
                ) : (
                  <Alert variant="danger" className="danger-msg">
                    {resultMsg} 
                  </Alert>
                )
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
export default ContactForm