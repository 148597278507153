import React from "react";
import { useTranslation } from "react-i18next";
import { instagram, linkedin } from "../../assets/links";
import { FaInstagram, FaLinkedin, FaLocationArrow, FaMailBulk, FaPhoneSquareAlt } from "react-icons/fa";

const FooterOne = ({ parentClass }) => {
  const { t } = useTranslation("translation", ["footer", "menu"])

  return (
    <footer className={`footer-area ${parentClass}`}>
      <div className="container">
        <div className="footer-top"></div>
        <div className="footer-main">
          <div className="row">
            <div className="col-xl-6 col-lg-5 col-md-4 col-sm-3">
              <div className="footer-widget border-end">
                <div className="footer-newsletter">
                  <h2 className="title">{t("footer.header")}</h2>
                  <div className="footer-menu-link">
                    <ul className="list-unstyled">
                      <li>
                        <a href={linkedin} target="_blank" rel="noreferrer noopener">
                          <p className="mb-0"> <FaLinkedin size={25}/> Linkedin </p>
                        </a>
                      </li>
                      <li>
                        <a href={instagram} target="_blank" rel="noreferrer noopener">
                          <p className="mb-0"> <FaInstagram size={25}/> Instagram </p>
                        </a>
                      </li>
                      <li>
                        <span className="mb-0" style={{ padding: '8px 0'}}> <FaMailBulk size={25}/> {t("footer.mail")} </span>
                      </li>
                      <li>
                        <span className="mb-0" style={{ padding: '8px 0'}}> <FaPhoneSquareAlt size={25}/> {t("footer.phone")} </span>
                      </li>
                      <li>
                        <span className="mb-0" style={{ padding: '8px 0'}}> <FaLocationArrow size={25}/> {t("footer.address")} </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-5 col-md-4 col-sm-3">
              <iframe 
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12250.233497469553!2d32.7365004!3d39.8617289!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d33f5f658eab15%3A0xb6c9e8a0b15f2399!2sObrosoft!5e0!3m2!1str!2str!4v1708090710538!5m2!1str!2str" 
                width="600" height="350" title="location" allowFullScreen={false}
                loading="lazy" referrerPolicy="no-referrer-when-downgrade" 
              />
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()}. {t("footer.rights")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default FooterOne;