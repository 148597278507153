import React from "react";
import SEO from "../../common/SEO";
import ColorSwitcher from "../../elements/switcher/ColorSwitcher";
import Header from "../../common/header/Header";
import FooterOne from "../../common/footer/FooterOne";
import BcrumbBannerTwo from "../../elements/breadcrumb/BcrumbBannerTwo";
import { useTranslation } from "react-i18next";

const CompanyPolicy = () => {
  const { t } = useTranslation("translation", { keyPrefix: "companyPolicy" });
  return (
    <>
      <SEO title="company-policy" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <Header />
        <BcrumbBannerTwo
          title={t("companyPolicyTitle")}
          paragraph={t("companyPolicy")}
          styleClass="thumbnail-1"
          mainThumb={process.env.PUBLIC_URL + "/images/companyPolicy/companyPolicy-img-1.png"}
        />
        <FooterOne />
      </main>
    </>
  );
};
export default CompanyPolicy;