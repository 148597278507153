import React from "react";
import SEO from "../../common/SEO";
import ColorSwitcher from "../../elements/switcher/ColorSwitcher";
import Header from "../../common/header/Header";
import FooterOne from "../../common/footer/FooterOne";

import Banner from "./Banner";
import Referneces from  "./Referneces";
import WorkTogether from "./WorkTogether";

const Home = () => {
  return (
    <>
      <SEO title="Obrosoft" />
      <ColorSwitcher />
      <main
        className="main-wrapper"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Header />
        <Banner />
        <Referneces />
        <WorkTogether />
        <FooterOne/>
      </main>
    </>
  )
}
export default Home