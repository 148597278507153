import React from "react";
import SEO from "../../common/SEO";
import ColorSwitcher from "../../elements/switcher/ColorSwitcher";
import Header from "../../common/header/Header";
import FooterOne from "../../common/footer/FooterOne";
import { useTranslation } from "react-i18next";

const ContentSolutions = () => {
  const { t } = useTranslation("translation", { keyPrefix: "contentSolutions" })
  const titles = t("contentTitles", { returnObjects: true })
  const desc = t("contentDescs", { returnObjects: true })

  const contentImg = [
    "/images/contentSolutions/content-img-1.png",
    "/images/contentSolutions/content-img-2.png",
    "/images/contentSolutions/content-img-3.png",
    "/images/contentSolutions/content-img-4.png",
    "/images/contentSolutions/content-img-5.png",
    "/images/contentSolutions/content-img-6.png"
  ]

  const displayProjects = titles.map((title, index) => {
    return (
      <div className="col" key={index}>
        <div className='project-grid h-100'>
          <div className="thumbnail" style={{ padding: '100px' }}>
            <img src={process.env.PUBLIC_URL + contentImg[index] } alt="icon" />
          </div>
          <div className="content">
          <h4 className="title"> 
            <span> {title} </span>
          </h4>
          <span className="subtitle">
            <span>{desc[index]}</span>
          </span>
          </div>
        </div>
      </div>
    )
  })

  return (
    <>
      <SEO title="company-policy" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <Header />
        <div className="banner banner-style-3">
          <div className="container">
            <div className="section-heading heading-left">
              <h2 className="title"> {t("contentSolutions")}  </h2>
            </div>
            <div className="container-fluid plr--30">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-3">
                {displayProjects}
              </div>
            </div>
          </div>
          <ul className="list-unstyled shape-group-3">
            <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-3.png"} alt="Comments" /></li>
            <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-10.png"} alt="Comments" /></li>
            <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Comments" /></li>
          </ul>
        </div>
        <FooterOne />
      </main>
    </>
  )
}

export default ContentSolutions