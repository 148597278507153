import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import enJSON from './locale/en.json';
import trJSON from './locale/tr.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: { ...enJSON } }, // Assuming your translation keys are nested under 'translation'
      tr: { translation: { ...trJSON } },
    },
    lng: "tr",
    interpolation: {
      escapeValue: false, // React already safely escapes interpolated values
    },
  });

export { i18n, useTranslation };
