import React from 'react';
import { useTranslation } from "react-i18next";
import SectionTitle from '../../elements/section-title/SectionTitle';
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import Slider from 'react-slick';

const Brands = () => {
  const { t } = useTranslation("translation", { keyPrefix: "brand" });
  const Data = [
    "/images/home/referneces/brand_1.png",
    "/images/home/referneces/brand_2.png",
    "/images/home/referneces/brand_3.png",
    "/images/home/referneces/brand_4.png",
    "/images/home/referneces/brand_5.png",
    "/images/home/referneces/brand_6.png",
    "/images/home/referneces/brand_7.png",
    "/images/home/referneces/brand_8.png",
    "/images/home/referneces/brand_9.png",
    "/images/home/referneces/brand_10.png",
    "/images/home/referneces/brand_11.png",
    "/images/home/referneces/brand_12.png",
    "/images/home/referneces/brand_13.png",
    "/images/home/referneces/brand_14.png",
    "/images/home/referneces/brand_15.png",
    "/images/home/referneces/brand_16.png",
    "/images/home/referneces/brand_17.png",
    "/images/home/referneces/brand_18.png",
    "/images/home/referneces/brand_19.png",
    "/images/home/referneces/brand_20.png",
    "/images/home/referneces/brand_21.png",
    "/images/home/referneces/brand_22.png",
    "/images/home/referneces/brand_23.png",
    "/images/home/referneces/brand_24.png",
    "/images/home/referneces/brand_25.png",
    "/images/home/referneces/brand_26.png",
    "/images/home/referneces/brand_27.png",
    "/images/home/referneces/brand_28.png",
    "/images/home/referneces/brand_29.png",
    "/images/home/referneces/brand_30.png",
    "/images/home/referneces/brand_31.png",
    "/images/home/referneces/brand_32.png",
    "/images/home/referneces/brand_33.png",
    "/images/home/referneces/brand_34.png",
    "/images/home/referneces/brand_35.png",
    "/images/home/referneces/brand_36.png",
    "/images/home/referneces/brand_37.png",
    "/images/home/referneces/brand_38.png",
    "/images/home/referneces/brand_39.png",
    "/images/home/referneces/brand_40.png",
    "/images/home/referneces/brand_41.png",
    "/images/home/referneces/brand_42.png",
    "/images/home/referneces/brand_43.png",
    "/images/home/referneces/brand_44.png",
    "/images/home/referneces/brand_45.png",
    "/images/home/referneces/brand_46.png",
    "/images/home/referneces/brand_47.png",
    "/images/home/referneces/brand_48.png",
    "/images/home/referneces/brand_49.png",
    "/images/home/referneces/brand_50.png"
  ]
  
  function SlickNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <FaAngleRight />
      </div>
    )
  }

  function SlickPrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}><FaAngleLeft /></div>
    )
  }

  var slideSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    rows: 3,
    nextArrow: <SlickNextArrow />,
    prevArrow: <SlickPrevArrow />,
  }

  return (
    <div className="section section-padding-equal bg-color-light">
      <div className="container">
        <SectionTitle 
          title={t("title")}
          textAlignment="heading-left mb--40"
          textColor=""
        />
        <div className="row">
          <Slider {...slideSettings} className="slick-arrow-nav">
            {Data.map((data, index) => (
              <div className="slide-item text-center m-2" key={index}>
                <img src={process.env.PUBLIC_URL + data} alt="Brand" width={200} height={100} style={{ objectFit: 'contain', maxHeight: '100px' }} />
              </div>
            ))}
          </Slider> 
        </div>
      </div>
      <ul className="list-unstyled shape-group-2">
        <li className="shape shape-1">
          <img src={process.env.PUBLIC_URL + "/images/others/line-2.png"} alt="Circle" />
          <img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Circle" />
        </li>
      </ul>
      <ul className="list-unstyled shape-group-8">
        <li className="shape shape-1">
          <img src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"} alt="Bubble" />
        </li>
        <li className="shape shape-2">
          <img src={process.env.PUBLIC_URL + "/images/others/bubble-30.png"} alt="Bubble" />
        </li>
      </ul>
    </div>
  )
}
export default Brands